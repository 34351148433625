import React from "react";
import View from "./ResearchView";
import Styles from "./Research.module.scss";

const Research = (props) => {
    

    return (
        <div className={Styles.wrapper}>
            <View/>
       </div>
    );
}

export default Research;