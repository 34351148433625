const priceList = { 
    "A/C_BLOWER_MOTOR": 18.99,
    "A/C_CAN/RESIVOIR": 9.99,
    "A/C_COMPRESSOR": 23.99,
    "A/C_CONDENSER": 19.99,
    "A/C_EVAPORATOR": 16.99,
    "A/C_SUITCASE_HOUSING": 14.99,
    "ABS_CONTROL_MODULE": 32.99,
    "ABS_PUMP": 65.99,
    "ACCESSORY_HANDLE": 3.49,
    "ACTUATOR": 10.99,
    "AIR_BAG_CNTRL_MOD": 16.99,
    "AIR_CLEANER_HOUS(LG)": 19.99,
    "AIR_CLEANER_HOUS(SM)": 15.99,
    "AIR_CLEANER_LID": 8.99,
    "AIR_FILTER": 1.99,
    "AIR_FLOW_SENSOR": 27.99,
    "AIR_INTAKE_ADJUSTER": 34.99,
    "AIR_RIDE_PUMP": 25.99,
    "ALTERNATOR": 16.99,
    "AMPLIFIER": 19.99,
    "ANTENNA": 3.99,
    "ANTENNA/POWER": 17.99,
    "ANTIFREEZE": 2.99,
    "ARM_REST": 6.99,
    "ASH_TRAY": 4.99,
    "AUTO_CLUTCH_ACTUATOR": 21.99,
    "AXLE_ASSY_FRONT_4X2": 109.99,
    "AXLE_ASSY_FRONT_4X4": 154.99,
    "AXLE_DISCONNECT_HSNG": 59.99,
    "AXLE_DROPOUT": 59.99,
    "AXLE_FRNT/REAR_NO_HU": 109.99,
    "AXLE_SHAFT_SOLID_TYP": 19.99,
    "BACK_GLASS": 19.99,
    "BALL_HITCH_MOUNT": 6.99,
    "BALL_JOINT": 3.99,
    "BATTERY_(USED)": 19.99,
    "BATTERY_BOX": 5.99,
    "BATTERY_CABLE": 2.99,
    "BCM/BT_MODULE": 29.99,
    "BC_UNLOADING_FEE": 5.00,
    "BEARNG/BUSHNG_(ANY)": 3.99,
    "BED_LINER": 29.99,
    "BELLHOUSING": 27.99,
    "BELT_TENSIONER": 14.99,
    "BLUETOOTH_MODULE": 24.99,
    "BRACKET_ANY": 0.54,
    "BRAKE_BACKING_PLATE": 12.99,
    "BRAKE_CALIPER": 15.99,
    "BRAKE_DRUM_(NO_HUB)": 17.99,
    "BRAKE_DRUM__W/HUB": 19.99,
    "BRAKE_FLUID_RESEVOIR": 5.99,
    "BRAKE_LINE/HOSE": 4.99,
    "BRAKE_MASTER_CYLINDR": 17.99,
    "BRAKE_PROPORTIONING": 8.99,
    "BRAKE_PWR/HYDRO_BSTR": 19.99,
    "BRAKE_ROTOR_(NO_HUB)": 14.99,
    "BRAKE_ROTOR_W/HUB": 19.99,
    "BRAKE_SHOE/PAD_(EA)": 1.79,
    "BRAKE_WHEEL_CYLINDER": 4.99,
    "BUCKET_SEAT": 26.99,
    "BUCKET_SEAT_POWER": 31.99,
    "BULBS": 0.99,
    "BULB_PLACEMENT_UNIT": 3.99,
    "BUMPER_COVER": 36.99,
    "BUMPER_END_CAP": 4.99,
    "BUMPER_FILLER": 8.99,
    "BUMPER_GUARD": 7.99,
    "BUMPER_METAL_BARE": 39.99,
    "BUMPER_PUSH_BAR": 19.99,
    "BUMPER_REINFORCEMENT": 18.99,
    "BUMPER_SHOCK": 9.99,
    "BUMPR_CVER_W/RENFORC": 41.99,
    "BUYING_CENTER_COPY": 1.00,
    "C-V_AXLE_FRNT__DRIVE": 28.99,
    "C-V_STUB_JOINT": 19.99,
    "CABLE_(ANY)": 11.99,
    "CAB_ONLY_BARE": 199.99,
    "CAMPER_SHELL": 100.99,
    "CAM_SHAFT": 19.99,
    "CARBURETOR": 29.99,
    "CARGO_NET": 5.00,
    "CARGO_RACK": 39.99,
    "CARPET": 6.99,
    "CARRIER_BEARING": 9.99,
    "CARRIER_HOUSING": 15.99,
    "CD/BT_PLAYER": 27.99,
    "CD/TEMP_CONTROL_CMBO": 47.99,
    "CD_CHANGER": 27.99,
    "CD_MAGAZINE": 5.99,
    "CENTER_CAP": 2.99,
    "CHARCOAL_CANISTER": 12.99,
    "CHARITY": 10.00,
    "CIGARETTE_LIGHTER": 1.99,
    "CLOCK": 5.99,
    "CLUTCH_DISC": 14.99,
    "CLUTCH_FORK": 13.99,
    "CLUTCH_MSTR_CYLINDER": 13.99,
    "CNG_FUELTANK_AS/IS": 49.99,
    "COIL": 9.99,
    "COIL_PACK": 29.99,
    "COIL_SPRING": 9.99,
    "COLD_AIR_INTAKE_SYS": 14.99,
    "COMPOSITE_BULB": 3.99,
    "COMPUTER_ECU": 29.99,
    "CONSOLE_(ANY)": 18.99,
    "CONSOLE_LID": 3.99,
    "CONSOLE_POWER": 24.00,
    "CONTROL_ARM": 19.99,
    "CONTROL_CABLE": 11.99,
    "CONVERTIBLE_TOP": 29.99,
    "CRANKSHAFT": 33.99,
    "CRUISE_CTRL_MODULE": 12.99,
    "CUP_HOLDER": 4.99,
    "DASH_(BARE)": 59.99,
    "DASH_PAD": 11.99,
    "DC_INVERTOR_CONVERT": 169.99,
    "DIESEL_ENGINE_NO_ACC": 199.99,
    "DIESEL_INJCTN_PUMP": 69.99,
    "DIFFERENTIAL": 124.99,
    "DIPSTICK/TUBE": 2.99,
    "DISTRIBUTOR": 19.99,
    "DISTR_CAP/NO_MODULE": 3.99,
    "DISTR_W/COIL_IGNITOR": 34.99,
    "DOMELIGHT_CONSOLE/LG": 24.99,
    "DOMELIGHT_CONSOLE/SM": 19.99,
    "DOME_LIGHT": 5.99,
    "DONUT_SPARE_TIRE": 11.99,
    "DOOR": 38.99,
    "DOOR_GLASS": 19.99,
    "DOOR_HANDLE_OUTSIDE": 7.99,
    "DOOR_SKIN": 25.99,
    "DRAG_LINK": 16.99,
    "DRINK": 1.50,
    "DRIVE_SHAFT": 24.99,
    "DRIVE_SHAFT_2_PIECE": 30.99,
    "DROPOUT_GEARS": 39.99,
    "DUAL_FAN/ASSY/SHROUD": 33.99,
    "DUAL_RESEVOIR": 7.99,
    "DUMP_UNIT__W/PUMP": 174.99,
    "DVD_PLAYER": 35.99,
    "EGR_VALVE_(EACH)": 8.99,
    "ELC_POWER_STEERING": 44.99,
    "ELEC._PARK_BRAKE": 29.99,
    "ELECT._SHIFT_ASSMBLY": 59.99,
    "ELECTRONIC_PEDAL": 14.99,
    "EMBLEM_(ANY)": 3.99,
    "EMBLEM_LETTER": 0.99,
    "EMPLOYEE_CUP": 1.00,
    "ENGINE_(NO_ACC)": 140.99,
    "ENGINE_BLOCK": 89.99,
    "ENGINE_CRADLE": 29.99,
    "ENGINE_MOUNT": 5.99,
    "ENGINE_NO_ACC_W/DIFF": 189.00,
    "EXHAUST_HEAT_SHIELD": 14.99,
    "FAN_ASSY._ELECTRIC": 26.99,
    "FAN_BELT": 2.99,
    "FAN_BLADE": 6.99,
    "FAN_CLUTCH": 11.99,
    "FAN_ELECTRIC_MOTOR": 13.99,
    "FAN_SHROUD": 9.99,
    "FENDER_ASSEMBLY": 42.99,
    "FENDER_BARE": 32.99,
    "FENDER_TRIM/FLARES": 8.99,
    "FLAT_BED_10-12W/LOAD": 375.00,
    "FLAT_BED_6-9_W/LOAD": 325.00,
    "FLOOR_MAT/SPARE_TIRE": 1.99,
    "FLYWHEEL": 19.99,
    "FRAME_COMPLETE": 187.99,
    "FRAME_SECTION": 9.99,
    "FRONT_VALANCE": 21.99,
    "FSWITCH_TURN_SIGNAL": 13.99,
    "FUELSEND/PMP/AS/IS": 26.99,
    "FUEL_DIVERTER_VALVE": 17.99,
    "FUEL_FILTER": 2.99,
    "FUEL_INJECTION_RAIL": 9.99,
    "FUEL_INJECTOR_(EACH)": 6.99,
    "FUEL_NECK": 9.99,
    "FUEL_PRESS/REG": 6.99,
    "FUEL_PUMP_(MECH)": 15.99,
    "FUEL_PUMP_ELECTRIC": 15.99,
    "FUEL_TANK/AS_IS": 28.99,
    "FUEL_TANK_FILL_DOOR": 5.99,
    "FUEL_TANK_SEND_UNIT": 12.99,
    "FUSE": 0.10,
    "FUSE_BOX_(BARE)": 12.99,
    "FUSE_BOX_COVER": 5.00,
    "FUSE_BOX_W/HARNESS": 30.99,
    "GAS/OIL_CAP": 2.99,
    "GASKET": 1.99,
    "GATE_FEE": 1.00,
    "GAUGE_SINGLE": 8.99,
    "GLASS_HATCH": 24.99,
    "GLOVEBOX_ASSY": 14.99,
    "GLOVEBOX_INSERT": 4.99,
    "GLOVES": 1.00,
    "GLOVE_BOX_DOOR": 7.99,
    "GRILLE": 17.99,
    "GRILLE_GAURD": 49.99,
    "GRILLE_W/_BEZELS": 24.99,
    "HARMONIC_BALANCER": 19.99,
    "HATCH_NO_GLASS": 24.99,
    "HATCH_WITH_GLASS": 41.99,
    "HEADERPANEL_FIBERGLA": 39.99,
    "HEADERPANEL_STEEL_BA": 29.99,
    "HEADERS_EXHS": 49.99,
    "HEADLAMP_BEZEL": 4.99,
    "HEADLAMP_DOOR_(EACH)": 9.99,
    "HEADLAMP_DOOR_MOTOR": 12.99,
    "HEADLAMP_RING": 1.49,
    "HEADLAMP_WIPER_MOTOR": 16.99,
    "HEADLIGHT": 12.99,
    "HEADLIGHT_COMPOSITE": 24.99,
    "HEADREST": 5.99,
    "HEAD_(ALUMINUM_NO_CA": 46.99,
    "HEAD_ALUMN_W/_CAM": 61.99,
    "HEAD_CASTIRON_NO_CAM": 33.99,
    "HEAD_CAST_IRON_W/CAM": 46.99,
    "HEAD_DUALCAM": 74.99,
    "HEAD_LINER": 16.99,
    "HEATER_CONTROL_VALVE": 4.99,
    "HEATER_CORE": 12.99,
    "HEATER_HOUSING": 14.99,
    "HEATER_RESISTER": 2.99,
    "HINGE": 6.99,
    "HOOD": 37.99,
    "HOOD/TRUNK_SHOCKS": 5.99,
    "HOOD_PROP_ANY": 1.99,
    "HOOD_RELEASE_CABLE": 6.99,
    "HORN": 5.99,
    "HORN_BUTTON": 4.99,
    "HOSE": 0.20,
    "HUB": 15.99,
    "HUB_CAP/WHEEL_COVER": 4.99,
    "HUB_LOCKING_TYPE_4X4": 11.99,
    "HYBRID_BATTERY": 325.00,
    "HYBRID_TRANSMISSION": 169.99,
    "HYDRA/PUMP_UNIT": 69.99,
    "IDLER_ARM": 11.99,
    "IGNITION_COIL": 9.99,
    "IGNITION_SWITCH": 13.60,
    "IGNITOR": 20.99,
    "INDPNDT__REAR_END": 159.99,
    "INJECTION_SPIDER": 12.99,
    "INNER_FENDER": 10.99,
    "INSTR_CLUSTER": 25.99,
    "INSTR_CLUSTR_BEZEL": 8.99,
    "INSTR_CLUSTR_W/TEMP": 35.99,
    "INSTR_LENS": 1.99,
    "INTAKE_MANIFOLD": 19.99,
    "INTAKE_PLENUM": 14.99,
    "INT_DOOR_HANDLE": 3.99,
    "INT_DOOR_PANEL": 10.99,
    "JACK_ANY": 9.99,
    "JACK_HANDLE": 2.00,
    "JUMPER_CABLES": 3.99,
    "KEYFOB_TRNSMTR_AS/IS": 5.00,
    "KNUCKLE/HUB_PRESSED": 29.99,
    "KNUCKLE/SPINDLE": 23.99,
    "LADDER_RACK": 75.00,
    "LATCH": 6.99,
    "LATCH_ELECTRIC": 19.99,
    "LCD_AUDIO/VIDEO_PLYR": 29.99,
    "LEAF_SPRING_-_LARGE": 39.99,
    "LEAF_SPRING_SMALL": 19.99,
    "LED_LIGHT_BAR_29IN<": 24.99,
    "LED_LIGHT_BAR_30IN>": 49.99,
    "LED_LIGHT_BULB": 19.99,
    "LIFTER": 1.99,
    "LIFT_GATE": 134.99,
    "LIGHT_LENS_ONLY": 0.79,
    "LOCK_TUMBLER": 6.99,
    "LUGGAGE_RACK": 19.99,
    "MANI_EXHST_(NO_SENS)": 22.99,
    "MANUAL_MIRROR-DOOR": 9.99,
    "MECH_STEERING__ASSY": 39.99,
    "MIRROR_INT+SWICTHES": 14.99,
    "MIRROR_INTERIOR": 3.99,
    "MODULE": 10.99,
    "MOLDING_EXTERIOR": 0.25,
    "MOLDING_INTERIOR": 0.25,
    "MUDFLAP": 3.99,
    "MUFFLER_(ANY)": 12.99,
    "MUFFLER_PIPE_LONG": 15.99,
    "MUFFLER_PIPE_SHORT": 7.99,
    "NAVIGATION_UNIT": 49.99,
    "NO_WARRANTY": 0.00,
    "OIL": 1.00,
    "OIL_COOLER": 7.99,
    "OIL_FILTER": 3.99,
    "OIL_FILTER_ADAPTER": 7.99,
    "OIL_LEVEL_SENSOR": 14.99,
    "OIL_PAN": 17.99,
    "OIL_PUMP": 12.99,
    "OIL_RESERVOIR": 5.99,
    "OVERDRIVE_UNIT": 35.99,
    "PARK/TURNLAMP_ASSY": 0.99,
    "PARK/TURNLAMP_LENS": 0.79,
    "PARK_BRAKE_ASSEMBLY": 15.99,
    "PCV_VALVE": 1.99,
    "PEDAL_(EACH)": 4.99,
    "PEDAL_ASSY": 19.99,
    "PISTON_AND_ROD_ASSY": 12.99,
    "PITMAN_ARM": 10.99,
    "POP_UP_HEADLAMP": 23.99,
    "POWER_MIRROR_-_DOOR": 19.99,
    "POWER_STEERING_PUMP": 20.99,
    "POWER_STEER_RESEVOIR": 5.99,
    "PRESSURE_PLATE": 12.99,
    "PULLEY_BARE": 7.99,
    "PUSH_TO_START_BUTTON": 9.99,
    "QUARTER_GLASS": 21.99,
    "QUARTER_PANEL": 49.99,
    "RACK_AND_PINION": 31.99,
    "RADIATOR": 37.99,
    "RADIATOR_BOTTLE": 4.99,
    "RADIATOR_SUPPORT_BAR": 22.99,
    "RADIO": 12.99,
    "RADIO_CABLE/SPEAKER": 1.99,
    "REAR_AXLE_LOADED": 119.99,
    "REAR_END_HOUSING_BAR": 29.99,
    "REAR_END_PINION&RING": 29.99,
    "REAR_END_SPIDER/SIDE": 9.99,
    "REAR_I-BEAM/NO_HUB": 69.99,
    "REAR_WINDOW_GUARD": 49.99,
    "RECEIVER_DRIER": 15.99,
    "REFILL": 1.00,
    "RELAY_(EACH)": 2.99,
    "RELAY_BOX": 6.99,
    "RESONATOR_EXHAUST": 10.99,
    "REVERSE_CAMERA": 29.99,
    "ROCKER_ARM": 3.99,
    "ROCKER_PANEL": 19.99,
    "ROLE_BAR_NO-LIGHTS": 39.99,
    "ROOF_CUT_OFF": 79.99,
    "RUNNING_BOARD": 15.99,
    "SCISSOR_LIFT": 149.99,
    "SCREWS/BOLTS": 0.25,
    "SEALED_BEAM/FOGLIGHT": 4.99,
    "SEAT_BELT/RETRACTOR": 13.99,
    "SEAT_BELT_CLIP": 3.99,
    "SEAT_BELT_MOTOR": 19.99,
    "SEAT_BELT_TRACK": 9.99,
    "SEAT_BENCH": 30.99,
    "SEAT_BENCH_COMBO_N/P": 39.99,
    "SEAT_BENCH_POWER": 34.99,
    "SEAT_BNCH_COMBO_PWR": 45.99,
    "SEAT_COVER_(EACH)": 2.99,
    "SEAT_MOTOR": 9.99,
    "SEAT_SECTION": 12.99,
    "SEAT_TRACKSET+MOTOR": 24.99,
    "SEAT_TRACKSET": 19.99,
    "SEAT_TRACK_SINGLE": 9.99,
    "SENSOR_(EMMISSIONS)": 8.99,
    "SENSOR_(OIL/WATER)": 2.99,
    "SENSOR_MISC.": 6.99,
    "SHIFTER_BOOT": 2.99,
    "SHIFTER_COLMN_COLLAR": 11.99,
    "SHIFTER_LEVER_ASSY": 14.99,
    "SHIFT_KNOB": 2.99,
    "SHIFT_KNOB_W/_SWITCH": 3.99,
    "SHIFT_MODULE": 9.99,
    "SHOCK": 7.99,
    "SIDE_DOOR_SLIDE_P/U": 59.99,
    "SLAVE_CYLINDER": 9.99,
    "SMOG_PUMP": 11.99,
    "SNOWPLOW": 199.99,
    "SOLENOID": 5.99,
    "SPARE_TIRE_CARRIER": 13.99,
    "SPARE_TIRE_COVER": 4.99,
    "SPARE_TIRE_SWNG_AWAY": 14.99,
    "SPARK_PLUG/WIRE": 0.75,
    "SPEAKER_(CUSTOM)": 7.99,
    "SPEAKER_(STOCK)": 4.99,
    "SPEAKER_BOX_NO_SPEAK": 14.99,
    "SPEAKER_TWEETER": 2.99,
    "SPEEDOMETER_CABLE": 8.99,
    "SPINDLE_KNUCKLEASSY": 62.99,
    "SPOILER+LIGHT": 25.99,
    "SPOILER": 22.99,
    "STABILIZER_LINK": 4.99,
    "STARTER": 16.99,
    "STEERING_BOX_ANY": 29.99,
    "STEERING_COLUMN": 29.99,
    "STEERING_COLUMN_LINK": 10.99,
    "STEERING_COLUMN_MOD": 19.99,
    "STEERING_WHEEL": 7.99,
    "STEERING_WHEEL_COVER": 2.99,
    "STEERNG_WHL_W/SWITCH": 15.99,
    "STRUT_AND_SPRING": 14.99,
    "STRUT_NO_SPRING": 11.99,
    "SUNROOF/T-TOP": 14.99,
    "SUNROOF_ASSY+MOTOR": 49.99,
    "SUN_ROOF_ASSEMBLY": 33.99,
    "SUN_VISOR": 5.99,
    "SUPERCHARGER_NO_ACC": 189.99,
    "SUSP._AIR_BAG": 32.99,
    "SWAY_BAR": 15.99,
    "SWITCH_COMBO": 11.99,
    "SWITCH_SINGLE": 6.99,
    "TAILGATE_CABLE": 4.99,
    "TAILLIGHT_CIRC_BOARD": 9.99,
    "TAIL_GATE": 29.99,
    "TAIL_GATE_W/_GLASS": 40.99,
    "TAIL_LIGHT_ASSY": 0.99,
    "TAIL_LIGHT_BAR": 24.99,
    "TEMP._CNTRL_ELECTRIC": 18.99,
    "TEMP._CNTRL_MANUAL": 10.99,
    "TEMP._CNTRL_W/CASS": 34.99,
    "THERMOSTAT": 0.99,
    "THERMOSTAT_HOUSING": 3.99,
    "THROTTLE_BODY": 32.99,
    "TIE_ROD_EACH": 15.99,
    "TIE_ROD_END": 5.99,
    "TIMING_CHAIN": 4.99,
    "TIMING_GEAR": 4.99,
    "TIMING_GEAR_COVER": 6.99,
    "TIRE_(AS-IS)_15<": 17.99,
    "TIRE_(AS-IS)_16>": 19.99,
    "TIRE_PRESSURE_SENSOR": 9.99,
    "TOOLBOX_METAL": 49.99,
    "TOOLBOX_PLASTIC": 39.99,
    "TOOL_RENTAL": 5.00,
    "TORQUE_CONVERTER": 19.99,
    "TORSION_BAR": 19.99,
    "TRAILERHITCH_RECEIVE": 19.99,
    "TRAILER_HITCH": 49.99,
    "TRAILER_HITCH_SWITCH": 6.99,
    "TRANSFER_CASE_(_4X4)": 78.99,
    "TRANSMISSION": 84.99,
    "TRANSMISSION_GEAR": 59.99,
    "TRANSMISSION_MOUNT": 5.99,
    "TRANS_CROSS_MEMBER": 12.99,
    "TRANS_DUST_COVER": 5.99,
    "TRANS_EXT_HOUSING": 9.99,
    "TRANS_MOTOR": 17.99,
    "TRANS_VALVE_BODY": 33.99,
    "TRNK_LID_NO_LGHT/LNS": 21.99,
    "TRUCK_BED_BARE": 99.99,
    "TRUNKBED_CVR_SFT/HRD": 75.99,
    "TURBO_CHARGER": 49.99,
    "TURBO_INTERCOOLER": 19.99,
    "TURN/CRUISE/WIPER/CO": 18.99,
    "U-BOLT": 2.50,
    "UNDER_ENGINE_COVER": 14.99,
    "UTILITY_TRUCK_BED": 250.00,
    "VACUUM_ACTUATOR": 9.99,
    "VACUUM_LINE": 0.59,
    "VACUUM_PUMP": 19.99,
    "VALVE_COVER": 9.99,
    "VENT_(INTERIOR)": 1.99,
    "VENT_GLASS": 11.99,
    "VENT_GLASS_ASSY": 17.99,
    "VENT_PANEL_(COWL)": 10.99,
    "WASHER_BOTTLE": 5.99,
    "WASHER_BOTTLE_MOTOR": 4.99,
    "WASHER_FLUID": 1.00,
    "WATER_PUMP": 10.99,
    "WATER_PUMP_(ELECTRIC": 24.99,
    "WEATHER_STRIPPING": 0.75,
    "WHEELBARROW": 525.00,
    "WHEEL_ALUMINUM": 18.99,
    "WHEEL_SPACER": 9.99,
    "WHEEL_STEEL": 6.99,
    "WINCH": 95.00,
    "WINDOW_CRANK_HANDLE": 2.99,
    "WINDOW_CURTAIN": 4.99,
    "WINDOW_HINGE": 3.99,
    "WINDOW_MOTOR": 14.99,
    "WINDOW_REGULATOR": 12.99,
    "WINDOW_REG_W/MOTOR": 27.99,
    "WINDOW_TRUNK_CURTAIN": 10.00,
    "WINDSHIELD": 34.99,
    "WIPER_ARM": 3.49,
    "WIPER_BLADE": 1.00,
    "WIPER_MOTOR": 17.99,
    "WIPER_TRANSMISSION": 9.99,
    "WIRE_HARENESS": 2.39,
    "WIRE_PIGTAIL": 2.39,
    "YOKE": 7.99,
    "Y_PIPE_EXHAUST": 11.99
}

module.exports = {
    priceList
}