import React, { useState } from 'react'
import Part from "../../Part/Part"
import Styles from "./Parts.module.scss"


const Parts = (props) => {
    let { partMap, year, model, make } = props

    const [partIndex, setIndex] = useState(0)
    let partsSorted = Object.keys(partMap).sort((a, b) => partMap[b]['partInfo'].sales - partMap[a]['partInfo'].sales)
    let partsArray = partsSorted.map(partName => {
        return <Part title={partName} year={year} model={model} make={make} key={partName} mapPart={partMap[partName]} />
    })

    const scroll = (e) => {
        let maxIndex = partsArray.length - 1
        const {outerText} = e.target
        let newIndex = outerText == "<" ? partIndex - 1 : partIndex + 1
        if (newIndex > maxIndex || newIndex < 0){
            newIndex = newIndex < 0 ? maxIndex : 0 
        }
        setIndex(newIndex)
    }

    let contents = (
        <div className={Styles["w3-container"]}>
        {partsArray.length > 1 && <span className={Styles["arrow"]} onClick={scroll}>{"<"}</span>}
            <div className={Styles["partWrapper"]}>
                    {partsArray[partIndex]} 
            </div>
            {partsArray.length > 1 && <span className={Styles["arrow"]} onClick={scroll}>{">"}</span>}
        </div>

    )

    return contents
}

export default Parts