import React, {useState} from "react"
import Styles from "./ModelSearch.module.scss"

const ModelSearch = ({submitModelSearch, cancelModelSearch}) => {
    const [model, setModel] = useState("")
    return (<div className={Styles.modal}>
        <div className={Styles.modalContent}>
          <input onChange={(e) => setModel(e.target.value)} value={model} type="text" placeholder="Model" className={Styles.searchInput} />
          <button onClick={() => submitModelSearch(model)} className={Styles.searchButton}>Search</button>
          <button onClick={() => cancelModelSearch()} className={Styles.cancelButton}>
            Cancel
          </button>
        </div>
      </div>)
}

export default ModelSearch