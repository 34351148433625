import React, { useState, useContext } from 'react'
import CloseUp from "./CloseUp/CloseUp"
import Styles from "./Part.module.scss"
import PartOptions from "./PartOptions/PartOptions"
import { storeContext } from "../../../Store"

// https://hump8901j9.execute-api.us-east-2.amazonaws.com/v1/yardogimages/testfile.jpg

const Part = (props) => {
    const { title, mapPart, year, make, model } = props
    const { parts, partInfo } = mapPart
    const literDetails = getLiterDetails(parts)
    const { addPartToTicket, makes, getModels, getPartTitlesUsingCode } = useContext(storeContext)
    const [closeUpEnabled, toggleCloseUp] = useState(false)
    const [partOptionsEnabled, togglePartOptions] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const partImage = parts[currentIndex]['imgurl']


    const searchLocation = () => {

        let url = `https://www.google.com/search?q=${year}+${model.replace(/\s/g, '+')}+${title.replace(/\s/g, '+')}+location`
        window.open(url, '_blank');
    }
    const searchCompatibility = () => {
        const url = `https://www.ebay.com/sch/i.html?_from=R40&_nkw=${year}+${make}+${model}+${title}&_sacat=6028&LH_TitleDesc=0&LH_Complete=1&LH_Sold=1&_sop=10&rt=nc&LH_ItemCondition=4&_ipg=200&_udlo=50`;
        window.open(url, '_blank');
    }

    const savePart = (info) => {
        addPartToTicket(info)
        togglePartOptions(false)
    }



    const loadLiterDetails = () => {

    }

    let contents = (
        <div className={Styles["partInfoWrapper"]}>
            <img onClick={() => toggleCloseUp(!closeUpEnabled)} className={Styles["mainImage"]} src={partImage}></img>
            <div className={Styles["partInfo"]}>
                <h2 >{title}</h2>
                <div className={Styles["classificationInfo"]}>
                    {partInfo.classifiers.includes("D") ?
                        <img src={require("../../../images/diamond.png")} className={Styles['icon']} /> : false}
                    {partInfo.classifiers.includes("E") ? 
                    <img src={require("../../../images/egg.png")} className={Styles['icon']} /> : false}
                </div>
                <div className="spacer"></div>
                <div className={Styles['literDetails']}>
                    {literDetails}
                </div>
                <div className={Styles["partNumbers"]}>
                    <img className={Styles["partsImage"]} src={require("./icons/parts.png")} />
                    <p>{partInfo.sales}</p>

                    <img className={Styles["partsImage2"]} src={require("./icons/money.png")} />
                    <p>{partInfo.averagePrice}</p>
                    <div className="spacer"></div>
                    <img onClick={() => togglePartOptions(true)} className={Styles['optionsImg']} src={require("./icons/options.png")} />
                </div>

            </div>
            {closeUpEnabled && <CloseUp parts={parts} currentIndex={currentIndex} functions={{ toggleCloseUp, setCurrentIndex }} />}
            {partOptionsEnabled && <PartOptions makes={makes} title={title} salesPer={partInfo.sales} imgurl={parts[currentIndex]['imgurl']}
                price={partInfo.averagePrice} year={year} make={make} model={model}
                functions={{ togglePartOptions, savePart, searchLocation, searchCompatibility, getModels, getPartTitlesUsingCode }} />}
        </div>

    )

    return contents
}
function getLiterDetails(parts) {
    let details = {}
    parts.forEach(part => {
        let { title } = part
        let periodIndex = title.indexOf('.')
        if (~periodIndex) {
            let liters = title.substring(periodIndex - 1, periodIndex + 2)
            //check if liter is a number
            if (+liters * 0 === 0) {
                details[liters] ? details[liters] += 1 : details[liters] = 1
            }
        }
    })
    let literDetailsArray = Object.keys(details)
    if (literDetailsArray.length === 0) return false

    return literDetailsArray.map(detail =>
        <div key={detail} className={Styles['literDetail']}>
            <p className={Styles['icon']}>{detail}</p>
            <p className={Styles['value']}>{details[detail]}</p>
        </div>)
}

export default Part