import React, {useState} from "react";
import Styles from "./Car.module.scss";



const Car = (props) => {
    const { _id, yearFrom, yearTo, make, model, part, notify, updateHotListItem, deleteHotListCar, changeInputs } = props;
    const years = yearFrom === yearTo ? yearFrom : `${yearFrom}-${yearTo}`;
    const [dropStatus, setDropStatus] = useState("down");

    const handleNotifyClick = () => {
        updateHotListItem(_id, { notify: !notify });
    }
    const handleEditClick = () => {
        let newInputs = {
            yearFrom: yearFrom,
            yearTo: yearTo,
            make: make,
            model: model,
            part: part,
            editMode: true,
            hotListID: _id
        }
        changeInputs(newInputs)
    }

    const handleDeleteClick = () => {
        deleteHotListCar(_id);
    }
    const handleDropClick = () => {
        setDropStatus(dropStatus === "down" ? "up" : "down")
    }


    return (
        <>
            <div className={Styles.wrapper}>
                <p>{`${years} ${make} ${model === "any" ? "" : model}`}</p>
                <div className="spacer"></div>
                
                <i onClick={handleDropClick} className="material-icons">{dropStatus === "down" ? "expand_more" : "expand_less"}</i>
                <i onClick={handleEditClick}  className="material-icons">edit_note</i>
                {/* <i onClick={handleNotifyClick} style={notify ? { color: "#b64e29" } : {}} className="material-icons">notifications_active</i> */}
                <i onClick={handleDeleteClick} className="material-icons">delete_forever</i>
            </div>
            <div style={{ display: dropStatus === "down" ? "none": "block" }} className={Styles.wrapper}>
                <p>{`Part: ${part}`}</p>
            </div>
        </>
    );
}

export default Car;