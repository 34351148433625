import React, { useContext, useState } from 'react'
import Styles from "./PullForm.module.scss"
import { storeContext } from "../../Store"
import { Button } from "react-md"

const PullForm = () => {
    const storeData = useContext(storeContext);
    const { currentCar, currentPart, newTicket, togglePullForm } = storeData;
    const [form, setForm] = useState({
        title: currentPart.title,
        partId: currentPart.partID,
        imgUrl: currentPart.imgUrl,
        notes: "",
        yard: currentCar.yard
    })



    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }


    return (
        <div className={Styles.outterContainer}>
            <div className={Styles.innerContainer}>
                <h4 className={Styles.title}>{form.title}</h4>
                <div style={{ display: "flex" }}>
                    <textarea className={Styles.notesBox} name="notes"
                        placeholder="Notes:" rows="6" onChange={handleChange} />
                    
                    <img className={Styles.partImg} src={form.imgUrl} alt="part" />

                </div>

                <div className={Styles.buttonsContainer}>
                    <Button raised className={Styles.cancelButton} onClick={(e) => togglePullForm(false)} >Cancel</Button>
                    <Button raised onClick={(e) => {
                        newTicket(form)
                    }}
                        className={Styles.submitButton}>Submit</Button>

                </div>
            </div>

        </div>
    )
}


export default PullForm
