import React from 'react'
import Styles from "./CarDisplay.module.scss"

function CarDisplay(props) {
    const { currentCar, addToHotList } = props;
    //For future use.
    const hotListItem = {
        yearFrom: currentCar.year,
        yearTo: currentCar.year,
        make: currentCar.make,
        model: currentCar.model
    }
    const addHotList = (item) => {
        addToHotList(item);
    }
    const url = `https://www.ebay.com/sch/i.html?_from=R40&_nkw=${currentCar.year}+${currentCar.make}+${currentCar.model}&_sacat=6028&LH_TitleDesc=0&LH_Complete=1&LH_Sold=1&_sop=10&rt=nc&LH_ItemCondition=4&_ipg=200&_udlo=50`;
    //


    /// need to figure out how to style carDisplay to implement the addHotList function, she's primed and ready to go though.


    return currentCar !== "" ?
        (
            <div className={Styles.wrapper}>
                <h3 style={{display: "flex"}}>{`${currentCar.year} ${currentCar.make} ${currentCar.model}`}
                    <div className="spacer"></div>
                    <a target="_blank" href={url}>eBay</a>
                </h3>
                <div className="spacer" ></div>
                <h4 style={{ display: "flex", justifyContent: "space" }}>Yard: <p>{currentCar.yard}</p><div className="spacer" ></div> VIN: <p>{currentCar.vin}</p></h4>
                <div className={Styles.stats}>
                    <div className={Styles.statWrapper}>
                        <h4>Days old: <p>{currentCar.days}</p></h4>
                        <h4> Row: <p>{currentCar.row ? currentCar.row : ""}</p></h4>
                        <h4> Seller: <p>{currentCar.seller ? currentCar.seller : ""}</p></h4>
                    </div>
                    <div className={Styles.statWrapper}>
                        <h4> Cylinders: <p>{currentCar.cylinders ? `${currentCar.cylinders}` : ""}</p></h4>
                        <h4> Engine: <p>{currentCar.engine ? `${currentCar.engine}` : ""}</p></h4>
                        <h4> Fuel: <p>{currentCar.fuelType ? currentCar.fuelType : ""}</p></h4>
                    </div>
                    <div className={Styles.statWrapper}>
                        <h4> Color: <p>{currentCar.color || ""}</p></h4>
                        <h4> Series: <p>{currentCar.series || ""}</p></h4>
                        <h4> Trim: <p>{currentCar.trim ? currentCar.trim : ""}</p></h4>
                    </div>
                </div>
            </div>
        ) :
        (
            <div className={Styles.wrapper}>
                {/* <h3>Select A Vehicle</h3> */}
            </div>
        )
}

export default CarDisplay

//Vehicle Image HTTP request
// let imageInfo;
//                 if (!imgUrl) {
//                     imageInfo = await axios.get(`https://www.googleapis.com/customsearch/v1?key=AIzaSyC8osp0ZtameZrkgEq8_tdkAij5M4JoeQs&cx=011169725490198372687:pwamc865dju&q=%22${currentCar.year}%20${currentCar.make}%22%20${currentCar.model}%20${currentCar.color}&searchType=image`)
//                         .catch(err => console.log(err.message));
//                 }
//                 setImgUrl(imageInfo ? imageInfo.data.items[0].link : imgUrl);