import { useEffect, useState } from 'react'
import Styles from "./SaveNewPart.module.scss"


const SaveNewPart = (props) => {
    const { savePart, togglePartOptions, title, salesPer, price, imgurl, year, make, model, makes, getModels, locationSearch, searchCompatibility } = props
    const [saveInfo, changeInfo] = useState({
        cylinders: [],
        title: title,
        salesPer: salesPer,
        price: price,
        notes: "",
        imgurl: imgurl,
        compatibleCars: [{ yearFrom: year, yearTo: year, make, model, id: `${year}${year}${make}${model}` }],
        location: ""
    })

    const [compCarInputs, setCompCarInputs] = useState({
        showInputs: false,
        editMode: false,
        yearFrom: "",
        yearTo: "",
        make: "",
        model: "",
        id: "",
    })

    const [models, setModels] = useState([])

    useEffect(() => {
        let outterWrap = document.querySelector("#outter")
        outterWrap.style.top = `${window.scrollY}px`
    })

    const setNewCylinder = (number) => {
        if (!saveInfo.cylinders.includes(number)) {
            if (saveInfo.cylinders.length === 2) {
                changeInfo({ ...saveInfo, cylinders: [] })
            } else {
                changeInfo({ ...saveInfo, cylinders: [...saveInfo.cylinders, number] })
            }
        } else {
            changeInfo({ ...saveInfo, cylinders: saveInfo.cylinders.filter(x => x !== number) })
        }
    }

    const handleDropDownChange = (e) => {
        const { value } = e.target

    }
    const handleEditClick = async () => {
        let selector = document.querySelector("#cars")
        const { value } = selector
        if (value) {
            let foundCar = saveInfo.compatibleCars.find(x => x.id === value)
            let models = await getModels(foundCar.make)
            setModels(models)
            setCompCarInputs({ ...foundCar, showInputs: true, editMode: true })
        }

    }
    const handleConfirm = () => {
        const { yearFrom, yearTo, make, model, id } = compCarInputs
        if (compCarInputs.editMode) {
            let foundIndex = saveInfo.compatibleCars.findIndex(car => car.id === id)
            let arrayToEdit = saveInfo.compatibleCars
            arrayToEdit[foundIndex] = { yearFrom, yearTo, make, model, id }
            changeInfo({ ...saveInfo, compatibleCars: arrayToEdit })
            clearCompInputs()
        } else if (!(yearFrom && yearTo && make && model)) {
            alert("You cannot have any empty fields")
        } else {
            let foundDuplicate = saveInfo.compatibleCars.find(x => x.yearTo === yearTo && x.yearFrom === yearFrom && x.make === make && x.model === model)
            if (!foundDuplicate) {
                changeInfo({
                    ...saveInfo,
                    compatibleCars: [...saveInfo.compatibleCars, { yearFrom, yearTo, make, model, id: `${yearFrom}${yearTo}${make}${model}` }]
                })
            }
            clearCompInputs()
        }

    }


    const handleCancel = () => {
        clearCompInputs()
    }

    const handleInputChange = (e) => {
        const { value, id } = e.target
        changeInfo({ ...saveInfo, [id]: value })
    }

    const compatibleOptions = () => {
        let options = saveInfo.compatibleCars.map((car, index) => {
            const { yearTo, yearFrom, make, model, id } = car
            const yearRange = yearTo === yearFrom ? year : `${yearFrom}-${yearTo}`
            return (<option key={id} id={id} value={id}>{`${yearRange} ${make} ${model}`}</option>)
        })

        return options
    }



    const handleAddCar = () => {
        setCompCarInputs({ ...compCarInputs, showInputs: true })
    }
    const handleDeleteCar = () => {
        let idToDelete = document.querySelector("#cars").value
        let modifiedArray = saveInfo.compatibleCars.filter(car => car.id !== idToDelete)
        changeInfo({
            ...saveInfo,
            compatibleCars: modifiedArray
        })
        clearCompInputs()
    }

    const handleCompInput = (e) => {
        const { value, id } = e.target
        if (id === "yearFrom" && !compCarInputs.yearTo) {
            setCompCarInputs({ ...compCarInputs, yearFrom: value, yearTo: value })

        } else {
            setCompCarInputs({ ...compCarInputs, [id]: value })
        }
    }
    const handleMakeChange = async (e) => {
        const { value, id } = e.target
        let models = await getModels(value)
        setModels(models)
        setCompCarInputs({ ...compCarInputs, make: value })
    }



    const handleSave = () => {
        const { compatibleCars, title, salesPer, price } = saveInfo
        if (compatibleCars.length !== 0 && title, salesPer, price) {

        } else {
            alert("There must not be any empty fields, including compatible cars.")
        }
        savePart(saveInfo)
        togglePartOptions(false)
    }
    const makeOptions = () => {
        let options = makes.map((make) => {
            return (<option key={make} id={make} value={make}>{make}</option>)
        })

        return options
    }

    const modelOptions = () => {
        let options = models.map((model) => {
            return (<option key={model} id={model} value={model}>{model}</option>)
        })

        return options
    }

    const yearOptions = () => {
        let numbers = []
        for (let i = new Date().getFullYear(); i > 1964; i--) {
            numbers.push(i)
        }
        let options = numbers.map((number) => {
            return (<option key={number} id={number} value={number}>{number}</option>)
        })

        return options
    }

    const showNotesAndLocation = () => {
        return (<div className={Styles['locationNoteWrapper']}>
            <input id="location" type="text" placeholder="Where on the car?" value={saveInfo.location} onChange={handleInputChange} />
            <input id="notes" type="text" placeholder="Leave a note" value={saveInfo.notes} onChange={handleInputChange} />
        </div>)
    }

    const showCompInputs = () => {
        return (<div className={Styles['addCarInputs']} style={{ display: "flex" }}>

            <img className={Styles['addOrDeleteImg']} onClick={handleCancel} src={require("../../../../../images/cancel.png")} />
            <select className={Styles['yearDropdown']} onChange={handleCompInput} name="yearFrom" id="yearFrom" value={compCarInputs.yearFrom}>
                <option key="yearFrom" value="" disabled>Year</option>
                {yearOptions()}
            </select>
            <span>-</span>
            <select className={Styles['yearDropdown']} onChange={handleCompInput} name="yearTo" id="yearTo" value={compCarInputs.yearTo}>
                <option key="yearTo" value="" disabled>Year</option>
                {yearOptions()}
            </select>
            <select className={Styles['dropdown']} onChange={handleMakeChange} name="make" id="make" value={compCarInputs.make}>
                <option key="make" value="" disabled>Makes</option>
                {makeOptions()}
            </select>

            <select className={Styles['dropdown']} onChange={handleCompInput} name="model" id="model" value={compCarInputs.model}>
                <option key="model" value="" disabled>Models</option>
                {modelOptions()}
            </select>
            <div className={Styles['confirmDelete']}>
                <img className={Styles['addOrDeleteImg']} onClick={handleConfirm} src={require("../../../../../images/confirm.png")} />
            </div>
        </div>)
    }

    function clearCompInputs() {
        setCompCarInputs({ ...compCarInputs, model: "", id: "", showInputs: false, editMode: false })
    }

    return (


        <div className={Styles['innerWrap']}>
            <i className={`${Styles['compSearch']} material-icons`} onClick={searchCompatibility}>directions_car</i>
            <input id="title" type="text" value={saveInfo.title} onChange={handleInputChange} />
            <i className={`${Styles['locationSearch']} material-icons`} onClick={locationSearch}>search</i>
            <div className={Styles["partNumbers"]}>
                <img className={Styles["partsImage"]} src={require("../../icons/parts.png")} />
                <input id="salesPer" type="number" value={saveInfo.salesPer} onChange={handleInputChange} />

                <img className={Styles["partsImage2"]} src={require("../../icons/money.png")} />
                <input id="price" type="number" value={saveInfo.price} onChange={handleInputChange} />
            </div>
            <div className={Styles['addCarWrapper']}>
                <i onClick={handleEditClick} className="material-icons">edit_note</i>
                <select onChange={handleDropDownChange} name="cars" id="cars">
                    <option key="compatVehic" value="" disabled>Compatible Vehicles</option>
                    {compatibleOptions()}
                </select>
                <img onClick={handleAddCar} src={require("../../../../../images/addCar.png")} />
                <img onClick={handleDeleteCar} src={require("../../../../../images/deleteCar.png")} />
            </div>
            {compCarInputs.showInputs ? showCompInputs() : showNotesAndLocation()}
            <h4>Cylinders</h4>
            <div className={Styles['cylinderWrapper']}>
                <span onClick={() => setNewCylinder(4)} style={saveInfo.cylinders.includes(4) ? { color: "#b64e29" } : { color: "white" }} className={Styles['cylinderIcon']}>4</span>
                <span onClick={() => setNewCylinder(6)} style={saveInfo.cylinders.includes(6) ? { color: "#b64e29" } : { color: "white" }} className={Styles['cylinderIcon']}>6</span>
                <span onClick={() => setNewCylinder(8)} style={saveInfo.cylinders.includes(8) ? { color: "#b64e29" } : { color: "white" }} className={Styles['cylinderIcon']}>8</span>
            </div>
            <p onClick={handleSave}>Save</p>
        </div>

    )
}



export default SaveNewPart