import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import Store from "./Store"
import { BrowserRouter } from "react-router-dom"
require("./global.scss")

ReactDOM.render(
    <BrowserRouter>
        <Store>
            <App />
        </Store>
    </BrowserRouter>,
    document.getElementById('root')
)