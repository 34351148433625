import React, { useContext, useState } from "react";
import Styles from "./HotList.module.scss";
import InputCarBox from "./InputCarBox/InputCarBox";
import HotListCars from "./HotListCars/HotListCars";
import { storeContext } from "../../Store";

const HotList = () => {
    const storeData = useContext(storeContext);
    const { addToHotList, hotList, updateHotListItem, deleteHotListCar } = storeData;

    const [inputs, changeInputs] = useState(
        {
            yearFrom: "",
            yearTo: "",
            make: "",
            model: "",
            part: "",
            editMode: false
        }
    );

    function inputOnChange(e) {
        changeInputs({
            ...inputs,
            [e.target.name]: e.target.name === "notify" ? !inputs.notify : e.target.value
        })
    }

    function clearInputs() {
        changeInputs({
            yearFrom: "",
            yearTo: "",
            make: "",
            model: "",
            part: "",
            editMode: false,
            hotListID: ""
        })
    }

    function submitInputs() {
        const { yearFrom, yearTo, make, model, notify, part } = inputs;
        const newCar = {
            yearFrom,
            yearTo,
            make,
            model,
            notify,
            part,
        }

        inputs.editMode ? updateHotListItem(inputs.hotListID, newCar) : addToHotList(newCar);

    }

    return (
        <div className={Styles.wrapper}>
            <main>
                <InputCarBox inputs={inputs} submitInputs={submitInputs} inputOnChange={inputOnChange} clearInputs={clearInputs} />
                <div className={Styles.listWrapper}>
                    <HotListCars changeInputs={changeInputs} hotList={hotList} updateHotListItem={updateHotListItem}
                        deleteHotListCar={deleteHotListCar} />
                </div>

            </main>
        </div>
    );
}

export default HotList;