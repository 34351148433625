import React from 'react';
import CarList from "../CarList/CarList";
import PartList from '../PartList/PartList';
import Styles from "./ResearchView.module.scss"

function View() {
    
    return (
        <div className={Styles.wrapper}>
            <CarList />
            <PartList />
            {/* <CreatePullTicket /> */}
        </div>
    )
}

export default View
