import React, { useState, useEffect } from "react"
import Styles from "./PartSearch.module.scss"

const PartSearch = (props) => {
  const {
    togglePartSearchBar,
    getCodesForPartSearch,
  } = props

  const [partKeyword, changePartKeyword] = useState("")

  const changeKeyword = (e) => {
    changePartKeyword(e.target.value.toUpperCase())
  }

  const submitKeyword = (e) => {
    e.preventDefault()
    getCodesForPartSearch(partKeyword)
    togglePartSearchBar(false)
    
  }
  useEffect(() => {
    document.getElementById("partSearch").focus()
  })

  return (
    <div className={Styles.wrapper}>
      <form onSubmit={submitKeyword}>
        <input
          onChange={changeKeyword}
          type="text"
          name="partKeyword"
          id="partSearch"
          placeholder="Part:"
          value={partKeyword}
        />
        <div>
          <input
            onClick={() => togglePartSearchBar(false)}
            type="button"
            value="Cancel"
          />
          <input type="submit" value="Submit" />
        </div>
      </form>
    </div>
  )
}

export default PartSearch
