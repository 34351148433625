import React, { useEffect } from "react"
import Styles from "./CloseUp.module.scss"
import toProperCase from "../../../../helpers/toProperCase"
String.prototype.toProperCase = toProperCase
const CloseUp = (props) => {
    const { parts, functions, currentIndex } = props
    const { toggleCloseUp, setCurrentIndex } = functions

    useEffect(() => {

        let outterWrap = document.querySelector("#outter")
        outterWrap.style.top = `${window.scrollY}px`;

    }, [toggleCloseUp])

    const outterClick = (e) => {
        const { id } = e.target
        if (id == "outter") toggleCloseUp(false)
    }

    const scroll = (e) => {
        let maxIndex = parts.length - 1
        const { outerText } = e.target
        let newIndex = outerText == "<" ? currentIndex - 1 : currentIndex + 1
        if (newIndex > maxIndex || newIndex < 0) {
            newIndex = newIndex < 0 ? maxIndex : 0
        }
        setCurrentIndex(newIndex)
    }

    let content = (
        <div onClick={outterClick} id="outter" className={Styles['outterWrap']}>
            <div className={Styles['container']}>
                <span className={Styles["arrow"]} onClick={scroll}>{"<"}</span>
                <div className={Styles['innerWrap']}>
                    <h3>${parts[currentIndex]['price'].toFixed(2)}</h3>
                    <img src={parts[currentIndex]['imgurl']} />
                    <h3>{parts[currentIndex]['title'].toProperCase()}</h3>
                </div>
                <span className={Styles["arrow"]} onClick={scroll}>{">"}</span>
            </div>


        </div>
    )
    return content
}



export default CloseUp