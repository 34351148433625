import React, {useContext} from "react"
import { Route, Redirect } from "react-router-dom";
import { storeContext } from "./Store";

function ProtectedRoute(props) {
    const storeData = useContext(storeContext);
    const {user} = storeData;
    const { component: Component, ...rest } = props;
    return (
        user.token ?
            <Route {...rest} component={Component} /> :
            <Redirect to="/auth/signin" />
    )
}

export default ProtectedRoute;