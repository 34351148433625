import React, {useContext, useState} from 'react';
import {storeContext} from "../../Store";
import NavBar from "./NavBar/NavBar";
import Menu from "./UserMenu/Menu";
import Styles from "./Header.module.scss";

function Header(props) {
    const storeData = useContext(storeContext);
    const {logout, user: {fname}, user, refreshCars} = storeData;

    const [showMenu, toggleMenu] = useState(false);

    function handleDoubleClick(e){
        refreshCars();
    }

    return (
        <header className={Styles.header}>
        <i onClick={() => toggleMenu(!showMenu)}className="material-icons">account_box</i>
            <h2>{fname}</h2>
            <div className="spacer"></div>
            {user.token && <NavBar/>}
            <img alt="logo" src={require("../../images/bulldog.png")} onDoubleClick={handleDoubleClick} />
            {/* <h1>Yardog</h1> */}
            {showMenu && <Menu {...{toggleMenu}}/>}
        </header>
    )
}

export default Header
