function getHighlightColor(days) {
    switch (days) {
        case 1:
            return "oneDay";
        case 2:
            return "twoDay";
        case 3:
            return "threeFiveDay";
        case 4:
            return "threeFiveDay";
        case 5:
            return "threeFiveDay";
        case 6:
            return "sixSevenDay";
        case 7:
            return "sixSevenDay";
        default:
            return ""
    }
}

export default getHighlightColor