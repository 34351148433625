const yardNames = {
    OGDEN_PIC_A_PART: "PIC-A-PART OGDEN",
    OGDEN_TEAR_A_PART: "TEAR-A-PART OGDEN",
    SLC_PIC_N_PULL: "PICK-n-PULL Salt Lake City",
    SLC_TEAR_A_PART: "TEAR A PART SLC",
    SLC_PULL_N_SAVE: "PULL-N-SAVE Salt Lake City",
    OREM_PIC_A_PART: "PIC-A-PART OREM",
    SV_PULL_N_SAVE: "PULL-N-SAVE Springville"
}

export const displayedYardNames = {
    OGDEN_PIC_A_PART: "Ogden Pic a Part",
    OGDEN_TEAR_A_PART: "Ogden Tear a Part",
    OREM_PIC_A_PART: "Orem Pic a Part",
    SLC_PIC_N_PULL: "SLC Pick n Pull",
    SLC_PULL_N_SAVE: "SLC Pull n Save",
    SLC_TEAR_A_PART: "SLC Tear a Part",
    SV_PULL_N_SAVE: "SV Pull n Save"
}

export default yardNames;
