import React, { useState, useEffect, useRef } from "react"
import Styles from "./PullParts.module.scss"

const PullParts = (props) => {
  let { partMap, editTicket, deleteTicket } = props

  const [partIndex, setIndex] = useState(0)
  const [modalDetails, setModalDetails] = useState({
    show: false,
    id: "",
  })
  const modalRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setModalDetails({show:false, id: ""})
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [])

  let partsSorted = partMap.sort((a, b) => b.salesPer - a.salesPer)
  let partsArray = partsSorted.map((part) => {
    let { title, salesPer, price, imgUrl, location, notes, _id } = part
    return (
      <div className={Styles["partInfoWrapper"]} key={title}>
        <img className={Styles["mainImage"]} src={imgUrl} alt={title} />
        <div className={Styles["partInfo"]}>
          <h2>{title}</h2>
          <p>{location}</p>
          <p>{notes}</p>
          <div className="spacer"></div>
          <div className={Styles["partNumbers"]}>
            <img
              className={Styles["partsImage"]}
              src={require("../../Part/icons/parts.png")}
              alt="Parts"
            />
            <p>{salesPer}</p>
            <img
              className={Styles["partsImage2"]}
              src={require("../../Part/icons/money.png")}
              alt="Money"
            />
            <p>{price}</p>
            <div className="spacer"></div>
            <img
              onClick={() => setModalDetails({ show: true, id: _id })}
              className={Styles["optionsImg"]}
              src={require("../../Part/icons/options.png")}
              alt="Options"
            />
          </div>
        </div>
      </div>
    )
  })

  const handleEditClick = () => {
    editTicket({})
    setModalDetails({ show: false, id: "" })
  }
  const handleDeleteClick = () => {
    deleteTicket(modalDetails.id)
    setModalDetails({ show: false, id: "" })
  }

  const scroll = (e) => {
    let maxIndex = partsArray.length - 1
    const { outerText } = e.target
    let newIndex = outerText === "<" ? partIndex - 1 : partIndex + 1
    if (newIndex > maxIndex || newIndex < 0) {
      newIndex = newIndex < 0 ? maxIndex : 0
    }
    setIndex(newIndex)
  }

  let modal = (
    <div ref={modalRef} className={Styles["modal"]}>
      <div className={Styles["modalContent"]}>
        <p onClick={handleEditClick}>Edit</p>
        <p onDoubleClick={handleDeleteClick}>Delete</p>
      </div>
    </div>
  )

  return (
    <div className={Styles["w3-container"]}>
      {modalDetails.show && modal}
      {partsArray.length > 1 && (
        <span className={Styles["arrow"]} onClick={scroll}>
          {"<"}
        </span>
      )}
      <div className={Styles["partWrapper"]}>{partsArray[partIndex]}</div>
      {partsArray.length > 1 && (
        <span className={Styles["arrow"]} onClick={scroll}>
          {">"}
        </span>
      )}
    </div>
  )
}

export default PullParts
