import React, { useState, useEffect } from "react";
import Styles from "./ModelSearch.module.scss";

const ModelSearch = (props) => {
    const { setYardFilter, yardFilter, toggleModelSearchBar } = props;

    const [modelKeyword, changeModelKeyword] = useState("");

    const changeKeyword = (e) => {
        changeModelKeyword(e.target.value.toUpperCase());
    }

    const submitKeyword = () => {
        setYardFilter(
            {
                ...yardFilter,
                modelSearch: modelKeyword
            }
        )
    }
    useEffect(() => {
        document.getElementById("modelSearch").focus()
    })

    return (
        <div className={Styles.wrapper}>
            <form onSubmit={submitKeyword}>
                <input onChange={changeKeyword} type="text" name="modelKeyword" id="modelSearch"
                    placeholder="Model:" value={modelKeyword} />
                <div>
                    <input onClick={() => toggleModelSearchBar(false)} type="button" value="Cancel" />
                    <input type="submit" value="Submit" />
                </div>
            </form>


        </div>
    );
}

export default ModelSearch;