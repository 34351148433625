import Styles from "./InputCarBox.module.scss";


const InputCarBox = (props) => {
    const { inputs, clearInputs, inputOnChange, submitInputs } = props;
    
    return (
        <div className={Styles.wrapper}>
            <h2>Add to Hot List</h2>
            <input onChange={inputOnChange} type="text" name="yearFrom" placeholder="Year from" value={inputs.yearFrom} />
            <input onChange={inputOnChange} type="text" name="yearTo" placeholder="To" value={inputs.yearTo} />
            <input onChange={inputOnChange} type="text" name="make" placeholder="Make" value={inputs.make} />
            <input onChange={inputOnChange} type="text" name="model" placeholder="Model" value={inputs.model} />
            <div style={{display: "flex"}}>
                <input onChange={inputOnChange} type="text" name="part" value={inputs.part} placeholder="Part" />
                <div className={Styles.buttonWrapper}>
                    <button onClick={clearInputs}>Clear</button>
                    <button onClick={submitInputs}>Submit</button>
                </div>
            </div>


        </div>
    );
}

export default InputCarBox;