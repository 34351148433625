const getDays = (pastDate) => {
    if (!pastDate){
        pastDate = "01/01/2020"
    }
    pastDate = pastDate.replace(/-/g,"/")
    pastDate = new Date(pastDate).getTime()
    const today = new Date().getTime()
    return Math.ceil((today - pastDate) / (1000 * 3600 * 24)) || 1111
}

export default getDays;