const getYardCars = (yards, yardName, row, model) => {
  if (Object.keys(yards).length === 0 || !yardName || !row) return []
  let carsInRow = yards[yardName].cars.filter((car) => {
    return model !== "" ? car.model.toLowerCase().includes(model.toLowerCase()) && car.row == row : car.row == row
    
  })
  return carsInRow
}

export default getYardCars
