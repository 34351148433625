import React, { useState, useContext } from "react"
import Styles from "./VehicleCard.module.scss"
import { storeContext } from "../../../../Store"
import Parts from "./Parts/Parts"
import PullParts from "./PullParts/PullParts"
import getHighlightColor from "../../../../helpers/getHighlightColor"

const VehicleCard = (props) => {
  const {
    year,
    make,
    model,
    color,
    days,
    vin,
    notes,
    parts,
    pulls,
    cylinders,
    fuelType,
    researchOn,
    deletePartFromTicket,
    editPartFromTicket
  } = props

  const partData = researchOn ? parts : pulls

  const storeData = useContext(storeContext)
  const { cars, researchCar, setCars } = storeData
  const [showParts, toggleShowParts] = useState(false)
  const [showHighestPrice, toggleCarMoney] = useState(false)

  const { classification, partMap } = partData || {}

  async function toggleParts() {
    if (!showParts) {
      setTimeout(() => {
        const vicCard = document.getElementById(vin)
        vicCard.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }, 300)
    }
    toggleShowParts(!showParts)

    if (!partData) {
      const carIndex = cars.findIndex((x) => x.vin === vin)
      console.log("Started Research")
      const researchedParts = await researchCar(`${year} ${make} ${model}`)
      console.log(researchedParts)
      cars[carIndex][researchOn ? "parts" : "pulls"] = researchedParts
      setCars([...cars])
    }
  }

  return (
    <div id={vin} className={`${Styles.wrapper}`}>
      <div className={Styles["line1Wrapper"]}>
        <h3 onClick={toggleParts} style={notes ? { color: "orange" } : {}}>
          {`${(color && color + " -") || ""} ${String(year).substr(
            2
          )} ${model}`}
        </h3>
        <div className={Styles["vicInfoWrapper"]}>
          {cylinders && (
            <div className={Styles["detail"]}>
              <img src={require("../../../../images/engine.png")} />
              <p>{cylinders}</p>
            </div>
          )}
          {fuelType && (
            <div className={Styles["detail"]}>
              <img src={require("../../../../images/fuel.png")} />
              <p>{fuelType[0]}</p>
            </div>
          )}
          {partData && (
            <div className={Styles["detail"]}>
              <img src={require("../Part/icons/parts.png")} />
              <p>{researchOn ? Object.keys(partMap).length : partMap.length}</p>
            </div>
          )}
          <div className="spacer"></div>
          {partData && classification.classifiers.includes("D") ? (
            <img
              src={require("../../../../images/diamond.png")}
              className={Styles["icon"]}
            />
          ) : null}
          {partData && classification.classifiers.includes("E") ? (
            <img
              src={require("../../../../images/egg.png")}
              className={Styles["icon"]}
            />
          ) : null}

          {partData && (
            <div
              onClick={() => toggleCarMoney(!showHighestPrice)}
              className={Styles["carMoneyWrapper"]}
            >
              <p>
                {showHighestPrice
                  ? `$${classification.highestPartPrice}`
                  : `$${classification.totalAvgPartPrice}`}
              </p>
              <span>{showHighestPrice ? "Highest" : "Total"}</span>
            </div>
          )}

          <p
            className={`${Styles["daysIcon"]} ${
              Styles[getHighlightColor(days)]
            }`}
          >
            {days}
          </p>
        </div>
      </div>
      {showParts &&
        partMap &&
        (researchOn ? (
          <Parts year={year} model={model} make={make} partMap={partMap} />
        ) : (
          <PullParts partMap={partMap} editTicket={editPartFromTicket} deleteTicket={deletePartFromTicket} />
        ))}
      {notes && showParts && <h4>{`Hot List Notes: ${notes}`}</h4>}
    </div>
  )
}

export default VehicleCard
