import React from "react"
const createYardOptions = (yardMap) => {
  const yardNames = Object.keys(yardMap)
  yardNames.sort()

  const options = [
    <option key={0} value="" disabled>
      Select a Yard
    </option>,
    ...yardNames.map((yardName) => {
      let newOption = (
        <option key={yardName} value={yardName}>
          {`${yardMap[yardName].displayName}\u00A0\u00A0\u00A0${yardMap[yardName].lastUpdated}`}
        </option>
      )
      return newOption
    }),
  ]

  return options
}

export default createYardOptions
