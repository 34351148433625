import yardNames from "../../../helpers/yardNameEnums"
import { displayedYardNames } from "../../../helpers/yardNameEnums"
const sortCarsIntoYards = (cars) => {
  const sortedCarsMap = {}
  cars.forEach((car) => {
    const { yard, days } = car

    for (let yardName in yardNames) {
      if (yardNames[yardName] === yard) {
        if (!sortedCarsMap[yardName]) {
          sortedCarsMap[yardName] = {
            cars: [],
            lastUpdated: days,
            displayName: displayedYardNames[yardName],
          }
        }

        sortedCarsMap[yardName].cars.push(car)

        if (sortedCarsMap[yardName].lastUpdated > days) {
          sortedCarsMap[yardName].lastUpdated = days
        }
      }
    }
  })
  return sortedCarsMap
}

export default sortCarsIntoYards
