import React, { createContext, useState, useEffect } from "react"
import axios from "axios"
import getDays from "./helpers/getDays"
const authAxios = axios.create()
const userAxios = axios.create()
export const storeContext = createContext({})

userAxios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token")
  config.headers.Authorization = `Bearer ${token}`
  return config
})

const Store = (props) => {
  const [cars, setCars] = useState([])
  const [makes, setMakes] = useState([])
  const [hotList, setHotList] = useState([])
  const [currentCar, setCurrentCar] = useState("")
  const [pullTickets, setPullTickets] = useState([])
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || {}
  )
  const [yardFilter, setYardFilter] = useState({
    yard: "All Yards",
    sortBy: "recent",
    hotList: false,
    scroll: 0,
    partSearch: "", //This needs to go away. Make it set the codes as a state. Not the keyword.
    modelSearch: "",
    carCodes: [],
    partKeyword: "",
    recommended: false,
  })

  useEffect(() => {
    if (user.token) {
      getPullTickets()
      getHotList()
      getAllMakes()
      getCars()
    }
  }, [user])

  const getCars = (manAutoSearchDays = false) => {
    //Get cars is set on a 4 min timeout
    userAxios
      .post("/api/getCars", {
        manAutoSearchDays: manAutoSearchDays,
        timeout: 240000,
      })
      .then((response) => {
        let carList = response.data.map((x) => ({
          ...x,
          days: getDays(x.date),
        }))
        setCars(carList)
        // setCurrentCar(carList[0])
      })
      .catch((err) => {
        console.log(err.message)
        setCars([])
      })
  }

  const refreshCars = () => {
    //requires admin account
    userAxios.post("/api/car/refreshCars").then((result) => alert(result.data))
  }

  const getPullTickets = () => {
    userAxios
      .get("/api/pullticket")
      .then((response) => {
        setPullTickets(response.data)
      })
      .catch((err) => {
        console.log(err.message)
        setPullTickets([])
      })
  }

  const populatePartsAndPulls = async (cars) => {
    try {
      const populatedCarsPromise = await userAxios.post(
        "/api/getCars/populatePartsAndPulls",
        { cars: cars }
      )
      const populatedCars = populatedCarsPromise.data
      return populatedCars
    } catch (e) {
      alert(e.message)
      return []
    }
  }

  const getPartTitlesUsingCode = async (searchTerm) => {
    let titles = await userAxios.post(
      "/api/pullticket/getPartTitlesUsingCode",
      { searchTerm: searchTerm }
    )
    return titles.data || []
  }

  const getAllMakes = () => {
    userAxios.get("/api/pullticket/getAllMakes").then((result) => {
      let makes = result.data.sort()
      setMakes(makes)
    })
  }

  const getModels = async (make) => {
    let allModels = await userAxios.get(`/api/pullticket/getAllModels`, {
      params: { make: make },
    })
    return allModels.data.sort()
  }

  const login = (credentials) => {
    return authAxios.post("/auth/login", credentials).then((response) => {
      const {
        user,
        user: { token },
      } = response.data
      localStorage.setItem("user", JSON.stringify(user))
      localStorage.setItem("token", token)
      setUser(user)
      return response
    })
  }

  const logout = () => {
    localStorage.removeItem("user")
    localStorage.removeItem("token")
    setUser({})
  }

  const addToHotList = (hotListItem) => {
    userAxios.post("/api/hotlist", hotListItem).then((response) => getHotList())
  }

  const getHotList = async () => {
    userAxios
      .get("/api/hotlist")
      .then((results) => {
        setHotList(results.data || [])
      })
      .catch((err) => console.log(err.message))
  }

  const setAutoDays = (days) => {
    userAxios.post("/api/pullticket/setAutoDays", { days: days })
  }

  const updateHotListItem = async (id, values) => {
    //{notify, yearFrom, yearTo, make, model, notes}
    userAxios
      .put(`/api/hotlist/${id}`, values)
      .then((results) => {
        getHotList()
      })
      .catch((err) => console.log(err.message))
  }

  const deleteHotListCar = (id) => {
    userAxios
      .delete(`/api/hotlist/${id}`)
      .then((results) => {
        getHotList()
      })
      .catch((err) => console.log(err.message))
  }

  const addPartToTicket = (partObject) => {
    userAxios
      .post("/api/pullticket/newPart", partObject)
      .then((response, err) => {
        if (err) console.log(err.message)
      })
  }
  const deletePartFromTicket = (id) => {
    userAxios
      .delete(`/api/pullticket/deletePart/${id}`)
      .then((response, err) => {
        if (err) console.log(err.message)
      })
  }
  const editPartFromTicket = (updates) => {
    alert("Yay")
    // userAxios.put(`/api/pullticket/editPart`, updates)
    //     .then((response, err) => {
    //         if (err) console.log(err.message)
    //     })
  }

  const deleteDataBase = (yardName) => {
    if (yardName) {
      //cars in yard
      userAxios
        .post("/api/car/deleteCars/yard", { yardName: yardName })
        .then((result) => {
          alert("Deleted: " + result.data.result.deletedCount)
        })
    } else {
      // all cars
      userAxios.delete("/api/car/deleteCars")
    }
  }

  const updateCar = (newValues, vin) => {
    let newCars = cars.map((x) => {
      if (x.vin === vin) {
        let updatedCar = { ...x }
        for (let key in newValues) {
          updatedCar[key] = newValues[key]
        }
        return updatedCar
      }
      return x
    })
    setCars(newCars)
    userAxios.post("/api/car/updateCar", { values: newValues, vin: vin })
  }

  const researchCar = async (searchTerm) => {
    let partInfo = {}
    try {
      let response = await userAxios.post("/api/parts/", {
        searchTerm,
        manualSearch: true,
      })
      partInfo = response.data
    } catch (e) {
      console.log(e.message)
    }
    return partInfo
  }

  const changeCurrentCar = async (vin) => {
    document.querySelector(".part-list").scrollTo(0, 0)
    let carIndex = cars.findIndex((x) => x.vin === vin)
    let car = cars[carIndex]
    const { year, make, model, parts } = car
    if (!parts) {
      let researchedParts = await researchCar(`${year} ${make} ${model}`)
      // console.log(researchedParts)
      car.parts = researchedParts
      car.seen = true
      cars[carIndex] = car
      setCars(cars)
    } // I wish I knew how to use useReducer
    setCurrentCar(car)
  }
  ////////////////////////////////////////////////////////////////////////////////////////// EVERYTIME I RE RENDER THE PART SEARCH STARTS AGAIN
  const getCodesForPartSearch = async (keyword) => {
    try {
      let codes = await userAxios.post("/api/parts/getCodes", {
        keyword: keyword,
      })
      setYardFilter((prevFilter) => {
        return {
          ...prevFilter,
          carCodes: codes.data,
          partKeyword: keyword
        }
      })
      ///RIGHT HERE instead of returning, set the codes in the yard filter
    } catch (e) {
      console.log(e.message)
      return []
    }
  }

  return (
    <storeContext.Provider
      value={{
        cars,
        setCars,
        hotList,
        updateHotListItem,
        deleteHotListCar,
        currentCar,
        pullTickets,
        user,
        changeCurrentCar,
        setYardFilter,
        yardFilter,
        deleteDataBase,
        updateCar,
        login,
        logout,
        getCars,
        addToHotList,
        getHotList,
        refreshCars,
        researchCar,
        addPartToTicket,
        deletePartFromTicket,
        editPartFromTicket,
        makes,
        getModels,
        getPartTitlesUsingCode,
        setAutoDays,
        populatePartsAndPulls,
        getCodesForPartSearch,
      }}
    >
      {props.children}
    </storeContext.Provider>
  )
}

export default Store
