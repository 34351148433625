import React, { useContext, useState, useEffect } from "react"
import Styles from "./PartList.module.scss"
import Part from "../AutoResearch/Part/Part"
import { storeContext } from "../../Store"

function PartList() {
  const storeData = useContext(storeContext)
  const {
    getPartTitlesUsingCode,
    currentCar,
    yardFilter: { partKeyword },
  } = storeData
  const [partTitles, setTitles] = useState([])

  useEffect(() => {
    async function getAndSetTitles() {
      const titles = await getPartTitlesUsingCode(
        `${currentCar.year} ${currentCar.make} ${currentCar.model}`
      )
      setTitles(titles)
    }
    if (currentCar) getAndSetTitles()
  }, [currentCar, getPartTitlesUsingCode])

  let partsArray = []
  if (currentCar && currentCar.parts && currentCar.parts.partMap) {
    let {
      year,
      model,
      parts: { partMap },
      make,
    } = currentCar
    let carParts = Object.keys(partMap)
    carParts.sort(
      (a, b) => partMap[b]["partInfo"].sales - partMap[a]["partInfo"].sales
    )
    if (partKeyword) {
      carParts = carParts.filter((title) => {
        let titleLower = title.toLowerCase()
        let keywordLower = partKeyword.toLowerCase()
        return titleLower.includes(keywordLower)
      })
    }
    if (carParts.length > 0) {
      partsArray = carParts.map((partName) => {
        return (
          <Part
            year={year}
            model={model}
            make={make}
            title={partName}
            key={partName}
            mapPart={currentCar.parts.partMap[partName]}
          />
        )
      })
    }
  }

  let partArrayLen = partsArray.length
  let content = currentCar ? (
    partArrayLen > 0 ? (
      partsArray
    ) : (
      <h5>No qualified parts for this vehicle</h5>
    )
  ) : (
    <h5>Please select a vehicle</h5>
  )
  console.log(partTitles)
  return (
    <div className={`${Styles.wrapper} part-list`}>
      {currentCar && (
        <div className={Styles["partInfoWrapper"]}>
          <h4>Parts: {partArrayLen}</h4>
          <div className="spacer"></div>
          <h4>Saved Parts:</h4>
          <select>
            <option key="saved" value="" disabled>
              Part Titles
            </option>

            {partTitles.map((x) => (
              <option key={x}>{x}</option>
            ))}
          </select>
        </div>
      )}
      <div className={Styles["parts"]}>{content}</div>
    </div>
  )
  // }, [currentCar])
}

export default PartList
