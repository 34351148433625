import React from "react"
import Styles from "./RowCarousel.module.scss"

const RowCarousel = ({ rows, setSelectedRow, selectedRow }) => {
  const handleRowClick = (row) => {
    setSelectedRow(row)
    const rowToView = document.getElementById(row)

    // Scroll the element into the center of the viewport
    rowToView.scrollIntoView({
      behavior: "smooth", // You can use "auto" for instant scrolling
      block: "center",
      inline: "center",
    })
  }
  return (
    <div className={Styles["carousel"]}>
      {rows.map((rowData, index) => (
        <div
          key={index}
          id={rowData.row}
          className={`${Styles["row"]} ${
            rowData.row === selectedRow ? Styles["selectedRow"] : ""
          }${rowData.classNames.reduce((text, className, index) => {
            if (!className) return text
            return `${text} ${Styles[className]}`
          }, "")}`}
          onClick={() => handleRowClick(rowData.row)}
        >
          {rowData.row}
        </div>
      ))}
    </div>
  )
}

export default RowCarousel
