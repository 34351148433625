import React, { useState, useEffect } from "react";
import Styles from "./PriceList.module.scss";
import { priceList } from "../../helpers/priceList"

const PriceList = (props) => {

    const [keyword, setKeyword] = useState("");
    const [sortBy, changeSortBy] = useState("name");
    const [list, setList] = useState([]);
    const changeKeyword = (e) => {
        setKeyword(e.target.value.toUpperCase());
    }

    useEffect(() => {
        let tempList = [];
        for (let part in priceList) {
            if (part.includes(keyword) || keyword === "") {
                tempList.push({ [part]: priceList[part] });
            }
        }
        if (sortBy === "price") {
            tempList.sort((a, b) => a[Object.keys(a)[0]] - b[Object.keys(b)[0]]);
        }

        tempList = tempList.map(part => {
            return (<div className={Styles.rowWrapper}>
                <p>{convertPartName(Object.keys(part)[0])}</p><div className="spacer"></div><p>${part[Object.keys(part)[0]]}</p>
            </div>)
        })
        setList(tempList);
    }, [keyword, sortBy])

    const convertPartName = (name) => {
        let array = name.split("_");
        return array.join(" ");
    }

    return (
        <div className={Styles.wrapper}>
            <div className={Styles.filterWrapper}>
                <input onChange={changeKeyword} type="text" name="keyword" id="keyword"
                    placeholder="Search Keyword:" value={keyword} />
                <h4>Sort By:</h4>
                <span style={sortBy === "name" ? { color: "#b64e29" } : {}} onClick={() => changeSortBy("name")}>Name</span>
                <p>|</p>
                <span style={sortBy === "price" ? { color: "#b64e29" } : {}} onClick={() => changeSortBy("price")}>Price</span>
            </div>
            <div className={Styles.listWrapper}>
                {list}
            </div>

        </div>
    );
}

export default PriceList;