import React from "react"
import Styles from "./RowResults.module.scss"
import VehicleCard from "./VehicleCard/VehicleCard"

const RowResults = ({cars, researchOn, editPartFromTicket, deletePartFromTicket}) => {
    if (!researchOn) {
        cars = cars.filter(car => car.pulls)
    }
    const carList = cars.map(x => <VehicleCard key={x.vin} editPartFromTicket={editPartFromTicket} deletePartFromTicket={deletePartFromTicket} researchOn={researchOn} {...x} />);

    return (
        <div className={Styles['wrapper']}>
            {carList}

        </div>
    )
}

export default RowResults