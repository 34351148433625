import React, { useContext, useState, useEffect} from 'react'
import Styles from "./SignIn.module.scss"
import { storeContext } from '../../Store';


const SignIn = (props) => {
    const storeData = useContext(storeContext);
    const {login, logout} = storeData;
    const [form, setForm] = useState({
        email: "",
        password: "",
        errorMessage: ""
    })

    useEffect(() => {
        logout()
    }, [])

    const handleChange = (input) => {
        setForm({
            ...form,
            errorMessage: "",
            [input.name]: input.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        login(form)
            .then(() => {
                props.history.push("/research")
            })
            .catch(err => {
                console.log(err)
                setForm({
                    ...form,
                    errorMessage: err.response.data.message
                })
                // setTimeout(() => {
                //     setForm({
                //         ...form,
                //         errorMessage: "",
                //     })
                // }, 5000)
            })
    }


    return (
        <div className={Styles.wrapper}>
            <div className={Styles.signInBox}>
                <div className={Styles.title}>
                    <img alt="logo" src={require("../../images/bulldog.png")} />
                    <h1>Yardog</h1>
                </div>
                <form onSubmit={handleSubmit}>
                    <input className={Styles.inputBox} onChange={(e) => handleChange(e.target)}
                        type="text" placeholder="email" name="email" value={form.email} />

                    <input className={Styles.inputBox} onChange={(e) => handleChange(e.target)}
                        type="password" name="password" placeholder="password" value={form.password} />
                    {form.errorMessage && <p>{form.errorMessage}</p>}
                    <div className="spacer"></div>
                    <input type="submit" className={Styles.signInButton} value="Sign In" />
                </form>

            </div>

        </div>
    )
}

export default SignIn
