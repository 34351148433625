import React, { useContext } from "react";
import Styles from "./HotListCars.module.scss";
import Car from "./Car/Car";

const HotListCars = (props) => {

    const { hotList, updateHotListItem, deleteHotListCar, changeInputs } = props;

    hotList.sort((a, b) => {
        let aText = a.make + a.model
        let bText = b.make + b.model
        return (aText < bText) ? -1 : (aText > bText) ? 1 : 0
    })
    const cars = hotList.map(x => <Car key={x._id}
        updateHotListItem={updateHotListItem}
        deleteHotListCar={deleteHotListCar} changeInputs={changeInputs} {...x} />)

    return (
        <div className={Styles.wrapper}>
            {cars}
        </div>
    );
}

export default HotListCars;